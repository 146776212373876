import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {
  PATH_CONDITIONS,
  PATH_CONFIRMATION,
  PATH_CREATE,
  PATH_MAIN,
  PATH_RESULT,
  PATH_SIGN,
  PATH_VERIFY,
  ROUTE_PARAM_EMAIL,
  ROUTE_PARAM_EMAIL_HASH,
  ROUTE_PARAM_PROCESS_ID,
  ROUTE_PARAM_TYPE
} from '../application/data-types'
import {ConditionsComponent} from './conditions/conditions.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: PATH_MAIN,
    pathMatch: 'full'
  },
  {
    path: PATH_MAIN,
    loadChildren: () => import('../1-main/main.module').then(m => m.MainModule)
  },
  {
    path: 'test',
    loadChildren: () => import('../9-test/test.module').then(m => m.TestModule)
  },
  {
    path: `${PATH_CONDITIONS}/:${ROUTE_PARAM_TYPE}`,
    component: ConditionsComponent
  },
  // TODO: Compatibility routes. They can be deleted after a month or so (Today is 10/01/2024)
  {
    path: 'main/start',
    redirectTo: `${PATH_MAIN}/${PATH_CREATE}`
  },
  {
    path: 'verify/create/:processId',
    redirectTo: `${PATH_MAIN}/${PATH_VERIFY}/:${ROUTE_PARAM_PROCESS_ID}`,
  },
  {
    path: 'verify/verify/:processId/:emailHash',
    redirectTo: `${PATH_MAIN}/${PATH_CONFIRMATION}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL_HASH}`,
  },
  {
    path: 'sign/:processId/:email',
    redirectTo: `${PATH_MAIN}/${PATH_SIGN}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL}`,
  },
  {
    path: 'sign/result/:processId/:email',
    redirectTo: `${PATH_MAIN}/${PATH_SIGN}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL}/${PATH_RESULT}`,
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false, scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
