<div class="holder">
  <div (click)="goHome(['/'])" class="logo">
    <img alt="logo" ngSrc="/assets/logo.svg" height="93" width="112">
  </div>

  <div class="jhc-filler"></div>

  <mat-icon class="material-symbols-sharp menu-icon"
            (click)="menuClicked()"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin">{{ showMenu ? 'close' : 'menu' }}
  </mat-icon>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="showMenu"
  >
    <div class="menu-holder">
      <div class="menu-sub">
        <div class="menu-object-holder">
          <div class="menu-object"
               (click)="navigate(['/'])">Signera
          </div>
          <div class="menu-object"
               (click)="navigate(['/', PATH_CONDITIONS, 'integritet'])">Integritetspolicy
          </div>
          <div class="menu-object"
               (click)="navigate(['/', PATH_CONDITIONS, 'villkor'])">Villkor
          </div>
          <div class="menu-object"
               (click)="navigate(['/', PATH_CONDITIONS, 'faq'])">Frågor & svar
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
