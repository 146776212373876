import {Component, Renderer2} from '@angular/core'
import {Router} from '@angular/router'
import {PATH_CONDITIONS} from '../../application/data-types'
import {ImageService} from '../../services/image.service'

@Component({
  selector: 'jhc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  protected readonly PATH_CONDITIONS = PATH_CONDITIONS

  public showMenu = false

  constructor(
    private router: Router,
    private imageService: ImageService,
    private renderer: Renderer2
  ) {
  }

  public menuClicked(): void {
    this.showMenu = !this.showMenu

    // If the menu is open, hide Deal the Seal
    this.imageService.showBackground.next(!this.showMenu)

    // Block scrolling when menu is open
    if (this.showMenu) {
       this.renderer.setStyle(document.body, 'overflow', 'hidden')
    } else {
      this.renderer.removeStyle(document.body, 'overflow')
    }
  }

  public navigate(route: any[]): void {
    this.showMenu = false
    this.router.navigate(route).then(() => {
      // Deal the Seal can be shown again after navigating (or not, depending on the navigation, see subscription)
      this.imageService.showBackground.next(true)
    })
  }

  public goHome(route: any[]): void {
    this.showMenu = false
    this.router.navigate(route).then(() => this.imageService.showBackground.next(true))
  }
}
