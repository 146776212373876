<div #backgroundImage class="main">
  <div class="header">
    <jhc-header></jhc-header>
  </div>

  <div class="jhc-filler"></div>
  <router-outlet></router-outlet>

  <div class="jhc-filler"></div>

  <div class="footer">
    <jhc-footer></jhc-footer>
  </div>
</div>